import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"
import { map } from "lodash-es"

import extractMutations from "./mutations"
import extractActions from "./actions"

const mapFilters = filters => {
  return {
    car_type_ids: map(filters.carTypes, "id"),
    search_value: filters.searchValue
  }
}

const baseURI = "/companies/car_classes"

const store = new StoreItemsModule({
  baseURI: baseURI,
  presetActions: ["fetch", "delete"],
  withFilters: {
    carTypes: [],
    searchValue: ""
  },
  withSorting: true,
  withPagination: true,
  mapFilters
})

store.mergeState({
  item: {}
})

store.mergeMutations(extractMutations())
store.mergeActions(extractActions({ baseURI }), withLoading)

export default store
